<template>
  <font-awesome-icon
    v-if="props.sortable"
    :icon="icon"
    class="base-sort-icon"
  />
</template>

<script>
import { computed } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "VtSortControl",
  props: ["props"],
  components: {
    FontAwesomeIcon,
  },
  setup(props) {
    library.add(faSort);
    library.add(faSortUp);
    library.add(faSortDown);
    const icon = computed(() => {
      if (!props.props.sortStatus.sorted) return "sort";
      return props.props.sortStatus.asc ? "sort-up" : "sort-down";
    });
    return {
      icon,
    };
  },
};
</script>
